import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import User from "@/models/User";
import { IUser } from "@/types";
import { IAlert } from "@/types/alert";
import App from "@/App.tsx";
import { Simulate } from "react-dom/test-utils";
import change = Simulate.change;
import { conditionallyChangeLanguage } from "@/lib/i18n.ts";

export interface AppState {
  user: User;
  initialized: boolean;
  setInitialized: () => void;
  alerts: Record<string, IAlert>;
  alertTimeouts: Record<string, number>;
}

export interface AppActions {
  clear: () => void;
  login: (user: User) => void;
  addAlert: (alert: IAlert, alertTimeoutMs?: number) => void;
  removeAlert: (alertId: string) => void;
}

const ALERT_TIMEOUT = 30000;

const useAppZustand = create<AppState & AppActions>()(
  persist(
    (set: any, get: any): AppState & AppActions => ({
      user: User.getAnonymous(),
      initialized: false,
      alerts: {},
      alertTimeouts: {},
      clear: () => {
        console.log("clear", get().user);
        set({ initialized: true }, true);
        console.log("/clear", get().user);
      },
      setInitialized: () =>
        set(({ user }: { user: IUser }) => {
          if (!user) return { user: User.getAnonymous(), initialized: true };
          return { initialized: true };
        }),
      login: async (user: User) => {
        console.log("login ", user);
        await conditionallyChangeLanguage(user.language);
        set(() => ({ initialized: true, user }));
      },
      addAlert: (alert: IAlert, alertTimeout = ALERT_TIMEOUT) => {
        if (get().alerts[alert.id]) {
          console.warn("Ignoring duplicate alert", alert);
          return;
        }
        // Start timer to remove the alert
        let ref = setTimeout(() => {
          set((state: AppState) => {
            const copy = { ...state.alerts };
            if (alert.id) {
              delete copy[alert.id];
            }
            return {
              alerts: copy,
            };
          });
        }, alertTimeout);

        // Display the alert
        set((state: AppState) => {
          return {
            alerts: { ...state.alerts, [alert.id]: alert },
            alertTimeouts: { ...state.alertTimeouts, [alert.id]: ref },
          };
        });
      },
      removeAlert: (alertId: string) => {
        set((state: any) => {
          const copy = { ...state.alerts };
          if (copy[alertId]) {
            delete copy[alertId];
          }
          const timeoutsCopy = { ...state.alertTimeouts };
          if (timeoutsCopy[alertId]) {
            const ref = timeoutsCopy[alertId];
            delete timeoutsCopy[alertId];
            clearTimeout(ref);
          }
          return { alerts: copy, alertTimeouts: timeoutsCopy };
        });
      },
    }),
    {
      name: "app-storage",
      version: 1,
      migrate: (persistedState: any, version) => {
        console.log("migrate", version);
        if (version === 0) {
          if (!persistedState.user) return persistedState;

          // @ts-ignore
          persistedState.user.organizations.map(({ users }) => {
            // @ts-ignore
            users.map((user) => {
              user.roles = [user.role];
              delete user.role;
            });
          });
        }
        return persistedState;
      },
    },
  ),
);

export default useAppZustand;

export function logout(alert?: IAlert) {
  const alerts = alert ? { [alert.id]: alert } : {};
  useAppZustand.setState(
    {
      user: User.getAnonymous(),
      initialized: true,
      alerts,
    },
    true,
  );
}
